<template>
    <transition name="component-fade" mode="out-in">
        <div class="ui grid">
            <router-link to="/home"  id="homelink" class="home">{{mode}}</router-link>
            <div class="two column row" id="atlas">
                <div class="fourteen wide computer fourteen wide tablet fourteen wide phone column">
                    <img src="@/assets/images/al1.png">
                </div>
            </div>
            <div class="two column row">
                <div class="eight wide computer fourteen wide tablet fourteen wide phone column">
                    <h4>Atlas of Lighting</h4>
                    <h4>Web application</h4>
                    <h5>For MIT Civic Data Design Lab | MIT Center for Advanced Urbanism | Philips Lighting</h5>
                    <p>As visualization developer</p>
                    <p>{{p1}}</p>
                    <p>{{p2}}</p>
                </div>
                <div class="six wide computer fourteen wide tablet column">
                            <expandable-image
                                    :src="require('@/assets/images/al2.png')"
                            />
                    <expandable-image
                            :src="require('@/assets/images/al3.png')"
                    />
                </div>
            </div>
            <div class="two column row"><img src="@/assets/images/al5.png"><img src="@/assets/images/al6.png"></div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "AtlasoflightingView",
        data(){
            return {
                p1: "\n" +
                    "A collaboration between Philips Lighting, MIT Center for Advanced Urbanism, and\n" +
                    " MIT Civic Data Design Lab. Project is trying to respond to integrated understanding " +
                    "of the dynamics of metropolitan areas through the lens of data overlaid from various sources " +
                    "including unconventional datasets such user generated, and social media data, both quantitative" +
                    " and qualitative measures to provide a clear view of how cities operate. Conventional " +
                    "data includes NASA’s Night Light imagery data, USGS Land Cover, Census Population and " +
                    "American Community Survey household characteristics data, and unconventional data sets " +
                    "including Google Places data, and Instagram media, all being processed and summarized for" +
                    " 250 meters grid cells. The project outcome included an online web application where user " +
                    "could interact with all the data layers, filtering based on each measure to isolate certain " +
                    "areas, and explore the result on the map. \n" +
                    "​In this project, a number of innovative approaches are taken in terms of data processing" +
                    ", including a method for efficiently scrapping location based social media data, in a scalable" +
                    " and replicaple way. Also, designing and implementing an infrastructure for a" +
                    " “persistent data miner” to scrape Google Places and Instagram API’s for 20 U.S" +
                    " metropolitan areas. The data miner is made as a “distributed microservises infrastructure” " +
                    "using the Containerization technology and with a “ephemeral development approach”.\n" +
                    "Realizing the scale of the geography for which we are setting up scrapers, the conventional" +
                    " social media scraping approach is not time efficient. \n" +
                    "Mostly web scrapers apply a grid of points on top of a geography of interest, then they send " +
                    "api calls by going through each point (usually this is in the form of options of latitudes" +
                    ", and longitudes, as well as an option to set a radius for which results are going to be returned)" +
                    ". A caveat to using a set grid of points is that: api’s including google, and instagram" +
                    " have a limit to maximum number of result they return for each call to their api" +
                    " (appendix X— limits and rates). This requires that the distance between each" +
                    " two point to be small enough that it could return all the results in between" +
                    " the points. In case large geographies, this is problematic for large areas," +
                    " where the number of points in the grid would be too big.\n" +
                    "The scrapping strategy for location based social media data mining, which" +
                    " instead of calling the API by walking through a list of points from a grid," +
                    " starts with bigger grid size– low resolution, and only increases the resolution when necessary.",
                p2: "\t\n" +
                    "Using the containers’ technology from Docker, scraping data from google places" +
                    " api is done in two steps: \n" +
                    "1. capturing google places ids— making calls using the radar_search end-point.\n" +
                    "2. getting the details for each place— making calls using the get_place end-point.\n" +
                    "The results are stored in Mongodb database, and all infrastructure runs on cloud.\n" +
                    "The visual was prepared for training Philips Lighting " +
                    "staff for setting up their scraping infrastructures."
            }
        },
        computed:{
            mode: function () {
                let self = this;
                return self.$parent.mode
            }
        }
    }
</script>
<style scoped>
</style>